<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-enterprise-list' }">Enterprises</router-link> &gt;
                    <router-link :to="{ name: 'enterprise-dashboard', params: { enterpriseId: this.$route.params.enterpriseId } }">{{ enterpriseName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ enterpriseName }}</h1>
                <p class="text-caption text-center">Users</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <UserList/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';
import UserList from '@/components/enterprise-dashboard/UserList.vue';

export default {
    components: {
        UserList,
    },
    data: () => ({
        enterprise: null,
        error: null,
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
        enterpriseName() {
            return this.enterprise?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadEnterprise() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEnterprise: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).self.get();
                console.log(`enterprise/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.enterprise = response;
                } else {
                    // TODO: redirect back to enterprise list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load enterprise', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEnterprise: false });
            }
        },
    },
    mounted() {
        this.loadEnterprise();
    },
};
</script>
